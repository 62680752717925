import { gql } from 'graphql-request'
import useGraphqlMutation from './useGraphqlMutation'

const MUTATION = gql`
    mutation subscribeToNewsletter(
        $email: String!
        $firstName: String!
        $lastName: String!
        $url: String!
    ) {
        subscribeToNewsletter(
            email: $email
            firstName: $firstName
            lastName: $lastName
            url: $url
        )
    }
`

const useSubscribeToNewsletterMutation = (options) =>
    useGraphqlMutation(MUTATION, options)

export default useSubscribeToNewsletterMutation
