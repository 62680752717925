import React, { useState } from 'react'
import { Trans } from '@lingui/react'
import SbEditable from 'storyblok-react'
import { getStaticUrl } from '../../util'
import Logo from '../utils/Logo'
import Image from '../utils/Image'
import ContactPhoneLink from '../utils/ContactPhoneLink'
import FooterSocialLink from './FooterSocialLink'
import FooterPaymentIcons from './FooterPaymentIcons'
import FooterDisclaimerLink from './FooterDisclaimerLink'
import FooterMobileMenuItem from './FooterMobileMenuItem'
import FooterNewsletterSignup from './FooterNewsletterSignup'

const FooterMobile = ({ content }) => {
    const [activeMenuItem, setActiveMenuItem] = useState(null)
    const {
        address,
        openingHours,
        linkGroups = [],
        socialLinks = [],
        disclaimerLinks = [],
    } = content

    return (
        <SbEditable content={content}>
            <footer className="lg:hidden" data-testid="FooterMobile">
                <div className="bg-black w-full">
                    <ul className="px-4 divide-y divide-gray-800">
                        {linkGroups.map((group) => (
                            <FooterMobileMenuItem
                                content={group}
                                key={group._uid}
                                isOpen={
                                    activeMenuItem &&
                                    activeMenuItem._uid === group._uid
                                }
                                onToggle={(item) => {
                                    if (
                                        activeMenuItem &&
                                        item._uid === activeMenuItem._uid
                                    ) {
                                        setActiveMenuItem(null)
                                    } else {
                                        setActiveMenuItem(item)
                                    }
                                }}
                            />
                        ))}
                    </ul>
                </div>
                <div className="bg-white px-4">
                    <div className="pt-8">
                        <div className=" mb-8">
                            <Logo isHorizontal className="h-10" />
                        </div>
                        <div className="text-sm font-medium mb-3">
                            <ContactPhoneLink />
                        </div>
                        <div className="text-sm whitespace-pre mb-8 text-gray-600">
                            {openingHours}
                        </div>
                        <div className="mb-8">
                            <p className="text-sm text-gray-600">{address}</p>
                        </div>
                        <ul className="flex mb-8">
                            {socialLinks.map((link) => (
                                <FooterSocialLink
                                    content={link}
                                    key={link._uid}
                                />
                            ))}
                        </ul>
                    </div>
                    <FooterNewsletterSignup className="py-10 border-t border-gray-100" />
                    <FooterPaymentIcons />
                    <div className="border-t border-gray-100 pt-6">
                        <div className="flex justify-center mb-4 gap-4">
                            <Image
                                width={25}
                                height={32}
                                alt="GFG Garantiefonds Reizen"
                                src={getStaticUrl(
                                    'gfg-garantiefonds-reizen.gif'
                                )}
                            />
                            <Image
                                width={46}
                                height={32}
                                alt="IATA Accredited Agent"
                                src={getStaticUrl('iata-accredited-agent.jpg')}
                            />
                            <Image
                                width={32}
                                height={32}
                                alt="ASBL BeCommerce VZW"
                                src={getStaticUrl('B2C_bg.png')}
                            />
                            <Image
                                width={96}
                                height={32}
                                alt="Certified Travel Agent"
                                src={getStaticUrl('B2C_CERTA.png')}
                            />
                        </div>
                        <div className="flex justify-center mb-4 text-xs ">
                            <Trans id="Copyright - Connections" />{' '}
                            {new Date().getFullYear()}
                        </div>
                        <div className="flex flex-col items-center">
                            {disclaimerLinks.map((link) => (
                                <div className="mb-1" key={link._uid}>
                                    <FooterDisclaimerLink content={link} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </footer>
        </SbEditable>
    )
}

export default FooterMobile
