/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import clsx from 'clsx'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { Trans, useLingui } from '@lingui/react'
import { createFormValidation } from 'use-form-state'
import { isRequired, isValidEmail } from '@connections/utils'
import { getLocaleRoute } from '../../util/routes'
import usePathnameLocale from '../hooks/usePathnameLocale'
import useSubscribeToNewsletterMutation from '../hooks/useSubscribeToNewsletterMutation'
import Icon from './Icon'
import Input from './Input'
import Button from './Button'

const validation = createFormValidation([
    {
        path: 'email',
        validate: (value) => isRequired(value) && isValidEmail(value),
        message: <Trans id="The email is required" />,
    },
    {
        path: 'firstName',
        validate: isRequired,
        message: <Trans id="The first name is required" />,
    },
    {
        path: 'lastName',
        validate: isRequired,
        message: <Trans id="The last name is required" />,
    },
])

const NewsletterSignup = ({ className }) => {
    const { i18n } = useLingui()
    const pathname = usePathname()
    const { isLoading, mutateAsync: subscribe } =
        useSubscribeToNewsletterMutation()
    const [email, setEmail] = useState('')
    const [done, setDone] = useState(false)
    const [errors, setErrors] = useState([])
    const fullLocale = usePathnameLocale(true)
    const [lastName, setLastName] = useState('')
    const [firstName, setFirstName] = useState('')

    if (done) {
        return (
            <div className={clsx('flex py-4', className)}>
                <Icon name="check-circle" color="green" className="mr-4" />
                <p>
                    <Trans id="You successfully signed up for our newsletter!" />
                </p>
            </div>
        )
    }

    return (
        <div className={className}>
            <div className="mb-2 grid grid-cols-7 gap-y-2">
                <div className="col-span-3 mr-2">
                    <Input
                        value={firstName}
                        placeholder={i18n._(/*i18n*/ 'First name')}
                        onChange={(e) => setFirstName(e.target.value)}
                        hasError={errors.find(
                            ({ path }) => path === 'firstName'
                        )}
                    />
                </div>
                <div className="col-span-4">
                    <Input
                        value={lastName}
                        placeholder={i18n._(/*i18n*/ 'Last name')}
                        onChange={(e) => setLastName(e.target.value)}
                        hasError={errors.find(
                            ({ path }) => path === 'lastName'
                        )}
                    />
                </div>
                <div className="col-span-5">
                    <Input
                        value={email}
                        rounding="left"
                        placeholder={i18n._(/*i18n*/ 'Email')}
                        onChange={(e) => setEmail(e.target.value)}
                        hasError={errors.find(({ path }) => path === 'email')}
                    />
                </div>
                <Button
                    rounding="right"
                    variant="blue-filled"
                    isLoading={isLoading}
                    className="col-span-2"
                    iconAfterName={!isLoading && 'arrow-right'}
                    onClick={async () => {
                        const newErrors = validation({
                            email,
                            firstName,
                            lastName,
                        })
                        setErrors(newErrors)
                        const isValid = newErrors.length === 0
                        if (isValid) {
                            const variables = {
                                email,
                                lastName,
                                firstName,
                                url: pathname,
                            }
                            await subscribe(variables)
                            setDone(true)
                        }
                    }}
                >
                    <span className="hidden md:block">
                        <Trans id="Sign me up" />
                    </span>
                    <span className="block md:hidden">
                        <Trans id="Go" />
                    </span>
                </Button>
            </div>
            <p className="text-sm text-gray-600">
                <Trans id="We care about the protection of your data. Read our" />{' '}
                <Link
                    className="ml-2 font-medium underline"
                    href={getLocaleRoute({
                        locale: fullLocale,
                        pathname: '/legal/privacy-policy',
                    })}
                >
                    <Trans id="Privacy Policy" />
                </Link>
            </p>
        </div>
    )
}

export default NewsletterSignup
